import React from 'react';
import ReactDOM from 'react-dom';

class Index extends React.Component {
  render() {
    return (
      <div className="abscenter-parent">
        <div className="abscenter-child">
          <div className="blend-image">
            <div
              className="asdf"
              style={{ position: 'absolute' }}
              onClick={() => (window.location.href = 'update/latest')}
            />
          </div>
        </div>
      </div>
    );
  }
}

ReactDOM.render(<Index />, document.getElementById('root'));
